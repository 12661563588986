isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}

function checkMobile() {
    if (isMobile) {
        document.querySelector("body").classList.add("is--mobile");
        document.querySelector("body").classList.remove("is--desktop");
    } else {
        document.querySelector("body").classList.remove("is--mobile");
        document.querySelector("body").classList.add("is--desktop");
    }
}

checkMobile();
//
//
// if (document.querySelectorAll("#btn-go-top").length) {
//     let mybutton = document.querySelector("#btn-go-top");
//     window.onscroll = function() { scrollFunction() };
//
//     function scrollFunction() {
//         if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
//             mybutton.style.display = "block";
//         } else {
//             mybutton.style.display = "none";
//         }
//     }
//
//     {
//         document.querySelector("#btn-go-top").addEventListener("click", (e) => {
//             e.preventDefault();
//             window.scrollTo({
//                 top: 0,
//                 behavior: "smooth",
//                 passive: true,
//             });
//         });
//     }
// }
//
// function isInViewport(element) {
//     const rect = element.getBoundingClientRect();
//     return (
//         rect.top >= 0 && rect.left >= 0 &&
//         // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//     );
// }
//
// let swiperScript = 0;
//
window.addEventListener("scroll", function(e) {

    if (document.querySelectorAll(".header").length) {
        var navbar = document.querySelectorAll(".header")[0];

        function addFixedMenu() {
            if (window.pageYOffset >= navbar.clientHeight + 300) {
                navbar.classList.add("fixed-header-scroll");
                document.querySelector("body").classList.add("menu-is-fixed");
            } else {
                navbar.classList.remove("fixed-header-scroll");
                document.querySelector("body").classList.remove("menu-is-fixed");
            }
        }

        if (document.body.clientHeight > (window.innerHeight + 400)) {
            addFixedMenu();
        }
    }
});



let slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
let slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}

const navItemToOpenA = document.querySelectorAll('.nav__level-1>.menu__item--has-children>a');
const navItemToOpenLi = document.querySelectorAll('.nav__level-1>.menu__item--has-children');

//
const navItemToOpenA2 = document.querySelectorAll('.nav__level-2>li>a');
const navItemToOpenLi2 = document.querySelectorAll('.nav__level-2>li');
//
// const navItemToOpenA3 = document.querySelectorAll('.nav__level-3>li>a');
// const navItemToOpenLi3 = document.querySelectorAll('.nav__level-3>li');
//
// let loginBtn = document.querySelector(".login__btn");
// let loginWrapper = document.querySelector(".login__wrapper");
// let loginList = document.querySelector(".login__list");
//
// function closeLoginBox(){
//     if (loginBtn.classList.contains("open")) {
//         loginList.classList.remove("open");
//         loginBtn.classList.remove("open");
//     }else{
//         loginList.classList.add("open");
//         loginBtn.classList.add("open");
//     }
// }
//
//
if (document.querySelector("#nav-toggle")) {
    document.querySelector("#nav-toggle").addEventListener("click", function (e) {

        e.preventDefault();
        e.stopPropagation();//
        this.classList.toggle("open");
        if (this.classList.contains("open")) {//
            document.querySelector("body").classList.add("menu-open");
            document.querySelector(".navMain").classList.add("open");////////
        } else {

            // if (document.querySelector(".navMain ul.open")) {
            //     slideUp(document.querySelector(".navMain ul.open"), 300);
            // }
            document.querySelector(".navMain").classList.remove("open");
            document.querySelector("body").classList.remove("menu-open");//
        }
        e.preventDefault();
        e.stopPropagation();//
        closeLoginBox();
    })

}
//
// if (document.querySelector("#nav-toggle--open")) {
//     document.querySelector("#nav-toggle--open").addEventListener("click", function(e) {
//         document.querySelector("#nav-toggle").click();
//     });
// }
//
// let touchstartXMenu = 0
// let touchendXMenu = 0
//
// function checkDirection() {
//     if (touchendX < touchstartXMenu) alert('swiped left!')
//     if (touchendX > touchstartXMenu) alert('swiped right!')
// }
//
//
//
//
// document.addEventListener('touchstart', e => {
//     touchstartXmenu = e.changedTouches[0].screenX
// })
//
// document.addEventListener('touchend', e => {
//     touchendXMenu = e.changedTouches[0].screenX
//     checkDirection()
// })
//
//
//
//
//
// loginBtn.addEventListener("click", function() {
//     if (this.classList.contains("open")) {
//         loginList.classList.remove("open");
//         this.classList.remove("open");
//     } else {
//
//         loginList.classList.add("open");
//         this.classList.add("open");
//     }
//
//     if (document.querySelector("#nav-toggle").classList.contains("open")) {
//         if (document.querySelector(".navMain.open")) {
//             document.querySelector(".navMain.open").classList.remove("open");
//         }
//         slideUp(document.querySelector(".nav"), 300);
//         document.querySelector("body").classList.remove("menu-open");
//         document.querySelector("#nav-toggle").classList.remove("open")
//     }
//
// });
//
// // previousElementSibling
if (window.ontouchstart !== undefined) {
//     document.querySelector("body").classList.add("menu-mobile");
//
    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenLi[i].addEventListener("click", function (e) {

            // e.preventDefault();
            // e.stopPropagation();
            let submenu = this.querySelector('.nav__level-2');

            if (submenu.classList.contains("m-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m-open");
            } else {
                if (document.querySelector(".nav ul.m-open")) {
                    slideUp(document.querySelector(".nav ul.m-open"), 300);
                    document.querySelector(".nav ul.m-open").classList.remove("open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m-open");
            }
            if (document.querySelector(".nav ul.m2-open")) {
                slideUp(document.querySelector(".nav ul.m2-open"), 300);
                document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
            }

        });
    }
//     for (var i = 0; i < navItemToOpenA2.length; i++) {
//         navItemToOpenLi2[i].addEventListener("click", function(e) {
//             e.stopPropagation();
//
//             let submenu = this.parentNode.querySelector(".nav__level-3");
//
//             if (submenu.classList.contains("m2-open")) {
//                 slideUp(submenu, 300);
//                 submenu.classList.remove("m2-open");
//             } else {
//                 if (document.querySelector(".nav ul.m2-open")) {
//                     slideUp(document.querySelector(".nav ul.m2-open"), 300);
//                     document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
//                 }
//                 slideDown(submenu, 300);
//                 submenu.classList.add("m2-open");
//             }
//         });
//     }
//
//
//     // $(".team__item").click(function(e) {
//     //     if ($(this).hasClass("open")) {
//     //         $(this).removeClass("open");
//     //     } else {
//     //         $(".team__item.open").removeClass("open");
//     //         $(this).addClass("open");
//     //     }
//     // })
//
} else {
//     for (var i = 0; i < navItemToOpenA.length; i++) {
//         navItemToOpenA[i].addEventListener("click", function(e) {
//             // e.preventDefault();
//             // e.stopPropagation();
//         });
//         navItemToOpenA2[i].addEventListener("click", function(e) {
//             // e.preventDefault();
//             // e.stopPropagation();
//         });
//     }
    for (let i = 0; i < navItemToOpenLi.length; i++) {

        navItemToOpenLi[i].addEventListener("mouseenter", function (e) {
            document.body.classList.add("menu-d-open");

            this.querySelector('.nav__level-2').style.display = "flex";
            // this.querySelector('.nav__level-2>li:first-child>a').classList.add('active');

        });
    }
    for (let i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseleave", function (e) {
            this.querySelector('.nav__level-2').style.display = "none";
            this.classList.remove('active');

            document.body.classList.remove("menu-d-open");
        });
    }
//     for (var i = 0; i < navItemToOpenLi2.length; i++) {
//         navItemToOpenLi2[i].addEventListener("mouseenter", function(e) {
//             if (document.querySelector('.nav__level-2')) { document.querySelector('.nav__level-3').classList.remove("open"); }
//             if (document.querySelector('.nav__level-2 a.active')) {
//                 document.querySelector('.nav__level-2 a.active').classList.remove("active");
//             }
//             if (this.querySelector('.nav__level-3')) {
//                 this.querySelector('.nav__level-3').classList.add("open");
//             }
//
//         });
//     }
//     for (var i = 0; i < navItemToOpenLi2.length; i++) {
//         navItemToOpenLi2[i].addEventListener("mouseleave", function(e) {
//             if (this.querySelector('.nav__level-3')) {
//                 this.querySelector('.nav__level-3').classList.remove("open");
//             }
//             this.classList.add('remove');
//         });
//     }
//     for (var i = 0; i < navItemToOpenLi3.length; i++) {
//         navItemToOpenLi3[i].addEventListener("mouseenter", function(e) {
//             console.log("xxxx");
//             if (document.querySelector('.nav__level-4')) { document.querySelector('.nav__level-4').classList.remove("open"); }
//             if (document.querySelector('.nav__level-3 a.active')) {
//                 document.querySelector('.nav__level-3 a.active').classList.remove("active");
//             }
//             if (this.querySelector('.nav__level-4')) {
//                 this.querySelector('.nav__level-4').classList.add("open");
//             }
//
//         });
//     }
//     for (var i = 0; i < navItemToOpenLi3.length; i++) {
//         navItemToOpenLi3[i].addEventListener("mouseleave", function(e) {
//             if (this.querySelector('.nav__level-4')) {
//                 this.querySelector('.nav__level-4').classList.remove("open");
//             }
//             this.classList.add('remove');
//         });
//     }
//
//
//
//     loginWrapper.addEventListener("mouseenter", function() {
//         if (!loginBtn.classList.contains("open")) {
//             loginList.classList.add("open");
//             loginBtn.classList.add("open");
//         }
//
//     });
//     loginWrapper.addEventListener("mouseleave", function() {
//         if (loginBtn.classList.contains("open")) {
//             loginList.classList.remove("open");
//             loginBtn.classList.remove("open");
//         }
//
//     });
//     // for (var i = 0; i < teamItemList.length; i++) {
//     //     teamItemList[i].addEventListener("mouseenter", function(e) {
//     //         this.classList.add("open");
//     //     });
//     // }
//     // for (var i = 0; i < teamItemList.length; i++) {
//     //     teamItemList[i].addEventListener("mouseout", function(e) {
//     //         this.classList.remove("open");
//     //     });
//     // }
}
//
//

const  accordionClose=function(accordionBtn, accordionContent, accordion) {
    if(accordionBtn.classList.contains("active")) {
        accordionBtn.classList.remove("active");
    }
    accordionBtn.setAttribute('aria-expanded', false);
    accordionContent.classList.remove("active");
    accordionContent.setAttribute('aria-hidden', true);
    accordionContent.style.maxHeight = null;
    accordion.classList.remove("active");
}


const accordionOpen=function(accordionBtn, accordionContent, accordion) {
    accordionBtn.classList.add("active");
    accordionBtn.setAttribute('aria-expanded', true);
    accordion.classList.add("active");
    accordionContent.setAttribute('aria-hidden', false);
    accordionContent.classList.add("active");
    accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
}


function accordion(accordion) {
    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__btn");

    accordionBtn.addEventListener("click", function (e) {
        e.preventDefault();
        if (accordionBtn.classList.contains("active")) {
            accordionClose(accordionBtn, accordionContent, accordion)
        } else {
            accordionOpen(accordionBtn, accordionContent, accordion)
        }
    })
}

Array.prototype.map.call(document.querySelectorAll(".accordion"), function (item) {
    accordion(item);
});

function accordionCloseAll(accordionBtnAll) {
    const wrapper = accordionBtnAll.closest(".accordion__wrapper");
    accordionBtnAll.addEventListener("click", function (e) {
        e.preventDefault();

        if (accordionBtnAll.classList.contains("active")) {
            accordionBtnAll.classList.remove("active");
            let btns = wrapper.querySelectorAll(".accordion__btn.active");
            for (let i = 0; i < btns.length; i++) {
                const accordionBtn = btns[i];
                const accordion = accordionBtn.closest(".accordion");
                const accordionContent = accordion.querySelector(".accordion__content");
                accordionClose(accordionBtn, accordionContent, accordion);
            }
        } else {
            accordionBtnAll.classList.add("active");
            let btns = wrapper.querySelectorAll(".accordion__btn[aria-expanded='false']");
            for (let i = 0; i < btns.length; i++) {
                const accordionBtn = btns[i];
                const accordion = accordionBtn.closest(".accordion");
                const accordionContent = accordion.querySelector(".accordion__content");
                accordionOpen(accordionBtn, accordionContent, accordion);
            }
        }
    })
}

Array.prototype.map.call(document.querySelectorAll(".accordion__show-all"), function (item) {
    accordionCloseAll(item)
});

if (document.querySelector(".accordion__map")) {
    let mapBtn=document.querySelector(".accordion__map");
    let mapContent=document.querySelector(".map__wrapper");
    mapBtn.addEventListener("click", function (e) {
        e.preventDefault();
        if (mapBtn.classList.contains("active")) {
            accordionClose(mapBtn, mapContent, mapContent);
        } else {
            accordionOpen(mapBtn, mapContent, mapContent);
        }
    })
}


function accordionSearch(accordion) {
    let accordionContent = accordion.querySelector(".searchM__panel");
    let accordionBtn = accordion.querySelector(".searchM__accordion");

    function closeAccordionSearch() {
        accordionClose(accordionBtn, accordionContent, accordion);
        if (document.body.classList.contains("accordion-active")) {
            document.body.classList.remove("accordion-active");
        }
    }

    accordionBtn.addEventListener("click", function (e) {
        e.preventDefault();
        if (accordionBtn.classList.contains("active")) {
            closeAccordionSearch();
        } else {
            accordionOpen(accordionBtn, accordionContent, accordion);
            document.body.classList.add("accordion-active");
        }
    });

    //close accoedion when click other place
    document.body.addEventListener("click", (evt) => {
        const flyoutEl = accordionBtn;
        let targetEl = evt.target; // clicked element

        if (!targetEl.closest(".searchM__options")) {
            if (targetEl == flyoutEl) {
            } else {
                if (accordionBtn.classList.contains("active")) {
                    closeAccordionSearch()

                }
            }
        }
    });

    let accordionBtnClose = accordion.querySelectorAll(".searchM__mobileClose, .searchM__btnTab");
    for (let i = 0; i < accordionBtnClose.length; i++) {
        accordionBtnClose[i].addEventListener("click", function (e) {
            e.preventDefault();
            closeAccordionSearch();
        })
    }

}

Array.prototype.map.call(document.querySelectorAll(".searchM__fieldset"), function (item) {
    accordionSearch(item)
});


if (document.querySelector(".searchM__btnT")) {
    const btnsTab = document.querySelectorAll(".searchM__btnT");

    for (let i = 0; i < btnsTab.length; i++) {
        btnsTab[i].addEventListener("click", function (e) {
            e.preventDefault();
            let href = this.getAttribute("data-href");
            document.querySelector(".searchM__btnT.active").classList.remove("active");
            this.classList.add("active");
            document.querySelector(".searchM__tabPanel.active").classList.remove("active");
            document.getElementsByClassName(href)[0].classList.add("active");


        })

    }

}


//TAB
function tabsRun(wrapper) {
    var tabs = wrapper.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = wrapper.querySelectorAll('[role=tab]'); //get tabs again as a different variable
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab
        var tabPanelToOpen = el.target.getAttribute('aria-controls');
        if (!tabPanelToOpen) {

            tabPanelToOpen = el.target.close("button").getAttribute('aria-controls');
        } //get the aria-controls value of the tab that was clicked
        var tabPanels = wrapper.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");
        } //hide all tabpanels

        wrapper.querySelector("#" + tabPanelToOpen).setAttribute("aria-hidden", "false");
        wrapper.querySelector("#" + tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
}

Array.prototype.map.call(document.querySelectorAll(".tabs"), function (item) {
    tabsRun(item)
});


let touchstartX = 0;
let touchendX = 0;

if (document.querySelector('.elements__right')) {
    const gestureZone = document.querySelector('.elements__right');


    gestureZone.addEventListener('touchstart', function (event) {
        touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function (event) {
        touchendX = event.changedTouches[0].screenX;
        handleGesture();
    }, false);
}

function handleGesture() {
    if (touchendX >= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click();
        }
    }
    if (touchendX <= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click()
        }
    }

};


(function () {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {

            modalTrigger[i].onclick = function (e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);

                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function () {
                var modalWindow = this.parentNode.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function () {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);


}());


//kontakt-mapa


//ulubione


var http_requestX = false;

/*
data - dane do wysłania
callbackF - funkcja zwrotna
itemEl - elemnt na kórym wywołujemy funkcje zwrotną /opcjonalny
*/
function makeRequest(url = '/a/', method = 'POST', data, callbackF, itemEl) {

    http_requestX = false;

    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_requestX = new XMLHttpRequest();
        if (http_requestX.overrideMimeType) {
            http_requestX.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_requestX = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_requestX = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }

    if (!http_requestX) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }

    http_requestX.onreadystatechange = function () {
        alertContents2(http_requestX, callbackF, itemEl)
    };
    http_requestX.open(method, url, true);
    http_requestX.send(data);
}

function alertContents2(http_requestX, functionName, itemEl) {
    try {
        if (http_requestX.readyState == 4) {
            if (http_requestX.status == 200) {


                functionName(http_requestX.responseText, itemEl);


            } else {

                alert('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {

    }
}

function favouriteCountCallbackFunction(data) {

    let heart = document.querySelector(".header__cartAmount");
    let heart2 = document.querySelector(".headerControls__heart");
    heart.innerHTML = data;


    (data > 0) ? heart2.classList.add('active') : heart2.classList.remove('active');
    (data > 0) ? heart.innerHTML = data : heart.innerHTML = "0";

    if (!data > 0 && document.getElementById("favourte--info")) {
        document.getElementById("favourte--info").style.display = "block";
    }

}


function favouriteCallbackFunction(data, el) {

    if (data == "ok") {

        el.classList.toggle("btn--heartAvtive");
        if (el.querySelector("span")) {
            let txtEls = el.querySelectorAll("span");

            txtEls[0].classList.toggle("hide");
            txtEls[1].classList.toggle("hide");
        }
    }
    let token = document.querySelector("[name='__csrf__token__']").value;
    dataToSend = new FormData();
    dataToSend.set('a', 'ulubione-ile');
    dataToSend.set('__csrf__token__', token);
    makeRequest('/a/?a=ulubione-ile', "GET", dataToSend, favouriteCountCallbackFunction);
}

function favouriteAdd(el) {
    el.addEventListener("click", function (e) {
        e.preventDefault();
        let id = el.getAttribute("data-id");
        let token = document.querySelector("[name='__csrf__token__']").value;
        let action = "ulubione-dodaj";
        if (el.classList.contains("btn--heartAvtive")) {
            action = "ulubione-usun";
        }

        data = new FormData();
        data.set('a', action);
        data.set('produkt', id);
        data.set('__csrf__token__', token);
        makeRequest('/a/', "POST", data, favouriteCallbackFunction, el);

        if (el.classList.contains("js--removeProduct")) {
            el.closest(".product__item").remove();
        }

    })
}


Array.prototype.map.call(document.querySelectorAll(".btn--heart"), function (item) {
    favouriteAdd(item)
});


//Newsletter: uzupełnienie wartości inputa w modalu

if (document.getElementById("newsletterBtn")) {
    function newsletterCallbackFunction(data) {
        document.getElementById("checbox-ajax").innerText = data;

    }

    document.getElementById("newsletterBtn").addEventListener("click", function () {
        document.getElementById("newsletterEmailModal").value = document.getElementById("newsletterEmail").value;
        if (!this.classList.contains("j2--click")) {
            this.classList.add("j2--click");
            data = new FormData();
            let token = document.querySelector("[name='__csrf__token__']").value;
            data.set('__csrf__token__', token);
            makeRequest('/a/?a=newsletter-zgoda', "GET", data, newsletterCallbackFunction);

        }

    })


    document.getElementById("newsletterEmail").addEventListener("keypress", function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            document.getElementById("newsletterBtn").click();
        }
    })
}


if (document.getElementById("searchM__form")) {
    const inputContinents = document.querySelectorAll(".searchM__continent input");
    const wrapperInputsCountry = document.querySelectorAll(".searchM__country .form__checkbox");

    function filterSearch() {
        let filter = inputSearch.value.toUpperCase();
        let wrapperNotHidden = document.querySelectorAll(".searchM__country .form__checkbox:not(.form__checkbox--hide)");
        for (let i = 0; i < wrapperNotHidden.length; i++) {
            let a = (wrapperNotHidden[i].getElementsByTagName("label")[0]).innerText;

            let index = a.indexOf("(");
            a = a.slice(0, index);

            if (a.toUpperCase().indexOf(filter) > -1) {

                if (wrapperNotHidden[i].classList.contains("form__checkbox--hide")) {
                    wrapperNotHidden[i].classList.remove("form__checkbox--hide")
                }
            } else {

                wrapperNotHidden[i].querySelector("input").checked = false;
                wrapperNotHidden[i].classList.add("form__checkbox--hide")
            }
        }
    }

    function filterContinents() {
        const inputsContinentsChecked = document.querySelectorAll(".searchM__continent input:checked");
        let continents = [];
        for (let j = 0; j < inputsContinentsChecked.length; j++) {
            continents.push(inputsContinentsChecked[j].getAttribute("id"));
        }
        if (continents.includes("k_dookola-swiata") || continents.length == 0) {
            for (let j = 0; j < wrapperInputsCountry.length; j++) {
                if (wrapperInputsCountry[j].classList.contains("form__checkbox--hide")) {
                    wrapperInputsCountry[j].classList.remove("form__checkbox--hide")
                }
            }
        } else {
            for (let j = 0; j < wrapperInputsCountry.length; j++) {
                if (continents.includes(wrapperInputsCountry[j].getAttribute("data-continent"))) {
                    if (wrapperInputsCountry[j].classList.contains("form__checkbox--hide")) {
                        wrapperInputsCountry[j].classList.remove("form__checkbox--hide")
                    }
                } else {
                    wrapperInputsCountry[j].querySelector("input").checked = false;
                    wrapperInputsCountry[j].classList.add("form__checkbox--hide")
                }
            }
        }
    }

    for (let i = 0; i < inputContinents.length; i++) {
        inputContinents[i].addEventListener("change", function () {
            filterContinents();
            filterSearch();
        })
    }


    const inputSearch = document.getElementById("searchMDirection");
    inputSearch.addEventListener("keyup", function () {
        filterContinents();
        filterSearch();
    })

    const inputClear = document.getElementById("searchMDirectionClear");
    inputClear.addEventListener("click", function (e) {
        e.preventDefault();
        for (let j = 0; j < wrapperInputsCountry.length; j++) {
            if (wrapperInputsCountry[j].classList.contains("form__checkbox--hide")) {
                wrapperInputsCountry[j].classList.remove("form__checkbox--hide");
            }
            wrapperInputsCountry[j].querySelector("input").checked = false;
        }
        for (let j = 0; j < inputContinents.length; j++) {
            inputContinents[j].checked = false;
        }
        inputSearch.value = "";
    })
}


//counnt checked option
if (document.getElementById("searchM__form")) {
    let directionInputs = document.querySelectorAll("#kierunekPodrozy .form__checkbox input");
    let kindInputs = document.querySelectorAll("#rodzajWyjazdu .form__checkbox input");
    let termInputs = document.querySelectorAll("#termin .form__checkbox input");

    let directionBtn = document.querySelector("[data-target='#kierunekPodrozy']")
    let kindBtn = document.querySelector("[data-target='#rodzajWyjazdu']")
    let termBtn = document.querySelector("[data-target='#termin']")

    let directionPlaceholder = directionBtn.innerText;
    let kindPlaceholder = kindBtn.innerHTML;
    let termPlaceholder = termBtn.innerHTML;
    let name = "";

    function countAndFill(inputsArray, id, btn, placeholder, txt) {

        for (let i = 0; i < inputsArray.length; i++) {
            inputsArray[i].addEventListener("change", function () {
                let nr = document.querySelectorAll(`${id} .form__checkbox input:checked`).length;
                if (nr === 0) {
                    btn.innerHTML = placeholder;
                } else {
                    if (nr === 1) {
                        name = document.querySelector(`${id} .form__checkbox input:checked~label`).innerText;
                        let index = name.indexOf("(");

                        if (index > -1) {
                            name = name.slice(0, index);
                        }
                        btn.innerHTML = name;
                    } else {
                        btn.innerHTML = `${txt}: ${nr}`;
                    }
                }
            })
        }
    }

    countAndFill(directionInputs, "#kierunekPodrozy", directionBtn, directionPlaceholder, "Wybrane kierunki");
    countAndFill(termInputs, "#termin", termBtn, termPlaceholder, "Wybrane terminy");
    countAndFill(kindInputs, "#rodzajWyjazdu", kindBtn, kindPlaceholder, "Wybrane kategorie");

}






